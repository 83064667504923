<template>
<div>
    <v-card-text>
        <v-row>
            <v-col cols="12" sm="4" md="4">
                <!-- <v-dialog v-model="isDialogVisible" persistent max-width="600px"> -->
                <v-dialog fullscreen v-model="isDialogVisible" hide-overlay transition="dialog-bottom-transition">
                    <template #activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            Add Temp Reading
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title>
                            <span class="headline">Temp Entry</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Name" dense v-model="ItemCompletedBy"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="SubmitPerson" dense v-model="SubmitPerson"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="ListName" dense v-model="ListName"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="ItemPrompt" dense v-model="ItemPrompt"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" outlined @click="isDialogVisible = false">
                                Close
                            </v-btn>
                            <v-btn color="success" @click="submit">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col md="4" offset-md="4">
                <v-text-field v-model="search" :append-icon="icons.mdiMagnify" label="Search" single-line hide-details dense outlined></v-text-field>
            </v-col>
        </v-row>
    </v-card-text>

    <!-- Table -->
    <v-data-table :loading="this.$apollo.queries.TempChart.loading" loading-text="Loading... Please wait" :headers="headers" :items="TempChart" :search="search" :items-per-page="20" class="table-kitchen-sink">
    </v-data-table>

</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiDeleteOutline } from '@mdi/js'
import gql from 'graphql-tag'
import { InMemoryCache } from 'apollo-cache-inmemory'

const GET_TEMPCHART = gql`
  query getTempChart {
    TempChart {
      InstanceName
      ActualScore
      Deadline
      DisplayTime
      Incomplete
      ItemActualScore
      ItemCompletedBy
      ItemCompletedOn
      ItemPossibleScore
      ItemPrompt
      ItemResult
      SubmitTime
      fridgeId
      SubmitPerson
      PossibleScore
      PercentScore
      OnTime
      LocationName
      ListName
      Late
      ItemType
    }
  }
`

const ADD_TEMP = gql`
  mutation addTemp($ItemCompletedBy: String!, $SubmitPerson: String!, $ListName: String!, $ItemPrompt: String!) {
    insert_TempChart_one(
      object: {
        ItemCompletedBy: $ItemCompletedBy
        SubmitPerson: $SubmitPerson
        ListName: $ListName
        ItemPrompt: $ItemPrompt
      }
    ) {
      fridgeId
    }
  }
`

export default {
  name: 'tempChart',
  components: {},
  data() {
    return {
      ItemCompletedBy: '',
      SubmitPerson: '',
      ListName: '',
      loading: true,
      ItemPrompt: '',
      isDialogVisible: false,
      TempChart: [],
      headers: [
        {
          text: 'List Name',
          value: 'ListName',
        },
        {
          text: 'Location Name',
          value: 'LocationName',
        },

        {
          text: 'DisplayTime',
          value: 'DisplayTime',
        },
        {
          text: 'Deadline',
          value: 'Deadline',
        },
        {
          text: 'Completed',
          value: 'ItemCompletedOn',
          sortable: false,
        },
        {
          text: 'Submitted By',
          value: 'SubmitPerson',
          sortable: false,
        },
        {
          text: 'On Time',
          value: 'OnTime',
          sortable: false,
        },
        {
          text: 'Late',
          value: 'Late',
          sortable: false,
        },
      ],
    }
  },
  apollo: {
    TempChart: {
      query: GET_TEMPCHART,
    },
  },
  setup() {
    const search = ref('')
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
      },
      search,
    }
  },
  mounted() {
    this.currentDate()
  },
  methods: {
    submit(e) {
      e.preventDefault()
      const { ItemCompletedBy, SubmitPerson, ListName, ItemPrompt } = this.$data
      this.$apollo.mutate({
        mutation: ADD_TEMP,
        variables: {
          ItemCompletedBy,
          SubmitPerson,
          ListName,
          ItemPrompt,
        },
        refetchQueries: ['getTempChart'],
      })
      this.isDialogVisible = false
    },
    currentDate() {
      const date = new Date().toISOString().substr(0, 10)
      console.log(date)
      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      return dateTime
    },
    currentTime() {
      const current = new Date()
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      return time
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
